import React, { FC, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { SelectedMediaType } from '@models/media';
import { Answer } from '@models/survey';
import { speechToText } from '@redux/v4-survey/api';
import { createPortal } from 'react-dom';
import SpeechToTextModal from '@components/UI/SpeechToTextMoal';
import { TEXT } from '@constants/messages';
import ConfirmationModal from '@components/UI/ConfirmationModal';
import useDialog from '@hooks/useDialog';
import { showWarning } from '@utils/toast-alerts';

import playIcon from 'img/play-violet.svg';
import pauseIcon from 'img/pause-violet.svg';
import recordIcon from 'img/record.svg';
import crossIcon from 'img/modal-close.svg';
import stopIcon from 'img/stop-red.svg';

const {
    AUDIO_TO_TEXT_IN_PROGRESS,
    SPEECH_NOT_TRANSCRIBED_TO_TEXT,
    DELETE_AUDIO,
    DELETE_AUDIO_BODY,
    DELETE_AUDIO_AND_TEXT,
    DELETE_AUDIO_AND_TEXT_BODY,
    MICROPHONE_NOT_ALLOWED,
} = TEXT;

interface CustomAudioProps {
    audioBlob: Blob | null;
    setAudioBlob: React.Dispatch<React.SetStateAction<Blob>>;
    setMediaText: React.Dispatch<React.SetStateAction<string>>;
    setTextLength: React.Dispatch<React.SetStateAction<number>>;
    setShowRecordAudioSection: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedMediaType: React.Dispatch<
        React.SetStateAction<SelectedMediaType>
    >;
    setLocalAnswers: React.Dispatch<React.SetStateAction<Answer>>;
    textByAI: boolean;
    setTextByAI: React.Dispatch<React.SetStateAction<boolean>>;
    convertedTextFromSpeech: boolean;
    setConvertedTextFromSpeech: React.Dispatch<React.SetStateAction<boolean>>;
    setRecordingInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomAudio: FC<CustomAudioProps> = ({
    audioBlob,
    setAudioBlob,
    setMediaText,
    setTextLength,
    setShowRecordAudioSection,
    setSelectedMediaType,
    setLocalAnswers,
    textByAI,
    setTextByAI,
    convertedTextFromSpeech,
    setConvertedTextFromSpeech,
    setRecordingInProgress,
}) => {
    const { showDialog, dialogData, hideDialog } = useDialog(false);
    const intervalRef = useRef(null);
    const waveformRef = useRef(null);
    const positionSliderRef = useRef(null);
    const playPauseBtnRef = useRef(null);
    const recordBtnRef = useRef(null);
    const recordTimeDisplayRef = useRef(null);
    const playTimeDisplayRef = useRef(null);
    const waveSurferRef = useRef<WaveSurfer | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [recordingDuration, setRecordingDuration] = useState(0);
    const [isTranscribeDisabled, setIsTranscribeDisabled] = useState(true);

    // old States for audio player
    const [showSpeechModal, setShowSpeechModal] = useState(false);
    const [localAudioBlob, setLocalAudioBlob] = useState<Blob | null>(null);
    const [isNoTextFromSpeech, setIsNotTextFromSpeech] = useState<
        null | boolean
    >(null);
    const [showPopup, setShowPopup] = useState(false);

    const handleSpeechToText = () => {
        if (isRecording) {
            stopRecording((blob) => {
                convertSpeechToText(blob);
            });
        } else if (localAudioBlob) {
            convertSpeechToText(localAudioBlob);
        }
    };

    const convertSpeechToText = async (blob: Blob) => {
        setShowSpeechModal(true);
        const formData = new FormData();
        formData.append('audio', blob, 'recording.wav');

        try {
            const { data } = await speechToText(formData);
            setMediaText(data.data);
            setLocalAnswers({ answer: data.data });
            setTextLength(() => data.data.length);
            if (data.data.length > 0) {
                setTextByAI(true);
                setConvertedTextFromSpeech(true);
                setIsTranscribeDisabled(true);
            } else {
                setIsNotTextFromSpeech(true);
            }
        } catch (error) {
            showWarning(error.message);
        }
        setShowSpeechModal(false);
    };

    // Format time as mm:ss
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    // Update record time display and position slider
    const updateRecordTimeDisplay = () => {
        const currentTimeFormatted = formatTime(recordingDuration);
        const totalTimeFormatted = '1:00';
        recordTimeDisplayRef.current.innerHTML = `<span>${currentTimeFormatted}</span><span class="limit">/${totalTimeFormatted}</span>`;

        // Calculate position slider value based on recording duration
        const sliderValue = (recordingDuration / 120) * 100;
        positionSliderRef.current.value = Math.round(sliderValue);
    };

    // Update play time display
    const updatePlayTimeDisplay = (currentTime, recordedDuration) => {
        playTimeDisplayRef.current.textContent = `${formatTime(currentTime)}/${formatTime(recordedDuration)}`;
    };

    const updatePositionSlider = (currentTime, addClass = false) => {
        const sliderValue = (currentTime / 60) * 100;
        positionSliderRef.current.value = Math.round(sliderValue);
        const totalTimeFormatted = '1:00';
        const currentTimeFormatted = formatTime(currentTime);
        recordTimeDisplayRef.current.innerHTML = `<span>${currentTimeFormatted}</span><span class="limit">/${totalTimeFormatted}</span>`;

        if (addClass) {
            if (currentTime >= 30 && currentTime < 40) {
                document
                    .querySelector('.audio-recording')
                    .classList.add('orange');
                document
                    .querySelector('.textarea-box .message')
                    .classList.add('orange');
            } else if (currentTime >= 40) {
                document
                    .querySelector('.audio-recording')
                    .classList.remove('orange');
                document
                    .querySelector('.textarea-box .message')
                    .classList.remove('orange');
                document.querySelector('.audio-recording').classList.add('red');
                document
                    .querySelector('.textarea-box .message')
                    .classList.add('red');
            }
        }
    };

    const resetPositionSlider = () => {
        positionSliderRef.current.value = 0;
    };

    const startRecording = async () => {
        try {
            resetPositionSlider(); // Reset position slider to start
            const mediaRecorder = mediaRecorderRef.current;
            if (!mediaRecorder) {
                return showWarning(MICROPHONE_NOT_ALLOWED);
            }

            // Hide waveform during recording
            waveformRef.current.style.display = 'none';
            playTimeDisplayRef.current.style.display = 'none';
            recordTimeDisplayRef.current.style.display = 'inline-block';

            // Reset classes after recording is finished
            document
                .querySelector('.audio-recording')
                .classList.remove('orange', 'red');
            document
                .querySelector('.textarea-box .message')
                .classList.remove('orange', 'red');

            mediaRecorder.ondataavailable = (e) => {
                setAudioBlob(e.data);
                const url = URL.createObjectURL(e.data);
                waveSurferRef.current.load(url);
            };

            mediaRecorder.onstart = () => {
                setIsTranscribeDisabled(false);
                const startTime = Date.now();
                intervalRef.current = setInterval(() => {
                    const currentTime = Math.floor(
                        (Date.now() - startTime) / 1000
                    );
                    if (currentTime < 60) {
                        setRecordingDuration(currentTime);
                        updateRecordTimeDisplay();
                        updatePositionSlider(currentTime, true);
                    } else {
                        setIsRecording(false);
                        setRecordingInProgress(false);
                        stopRecording(); // Stop recording if 2 minutes are reached
                    }
                }, 1000);
            };

            mediaRecorder.onstop = () => {
                // setSelectedMediaType('audio');
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                waveSurferRef.current.on('ready', () => {
                    const duration = Math.min(
                        Math.floor(waveSurferRef.current.getDuration()),
                        60
                    );
                    setRecordingDuration(duration);
                    updatePlayTimeDisplay(0, duration); // Start with currentTime 0 when audio is ready
                    updatePositionSlider(0, true); // Set slider position to start when audio is ready
                });

                waveSurferRef.current.on('finish', () => {
                    setIsPlaying(false);
                    playPauseBtnRef.current.innerHTML = `<img src=${playIcon} alt="">`;
                });

                waveSurferRef.current.on('audioprocess', () => {
                    const currentTime = waveSurferRef.current.getCurrentTime();
                    const recordedDuration = Math.min(
                        Math.floor(waveSurferRef.current.getDuration()),
                        60
                    );
                    updatePlayTimeDisplay(currentTime, recordedDuration);
                    updatePositionSlider(currentTime, false);
                });

                // Show waveform after recording is finished
                waveformRef.current.style.display = 'block';
                // Show play button after recording is finished and make it clickable
                if (playPauseBtnRef.current) {
                    playPauseBtnRef.current.style.display = 'block';
                    playPauseBtnRef.current.disabled = false; // Ensure button is enabled
                }

                if (playTimeDisplayRef.current) {
                    playTimeDisplayRef.current.style.display = 'inline-block';
                }
                if (recordTimeDisplayRef.current) {
                    recordTimeDisplayRef.current.style.display = 'none';
                }

                if (intervalRef.current) {
                    positionSliderRef.current.style.display = 'none';
                    positionSliderRef.current.value = 0;
                }

                // Reset classes after recording is finished
                document
                    .querySelector('.audio-recording')
                    .classList.remove('orange', 'red');
                document
                    .querySelector('.textarea-box .message')
                    .classList.remove('orange', 'red');
            };

            mediaRecorder.start();
            recordBtnRef.current.innerHTML = `<img src=${stopIcon} alt="">`;
            setIsRecording(true);
            setRecordingInProgress(true);
        } catch (err) {
            showWarning(err.message);
        }
    };

    const stopRecording = (callback?: (data: Blob) => void) => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.ondataavailable = (e) => {
                setAudioBlob(e.data);
                const url = URL.createObjectURL(e.data);
                waveSurferRef.current.load(url);
                if (callback) callback(e.data);
            };

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            mediaRecorderRef.current.stop();

            recordBtnRef.current.innerHTML = `<img src=${recordIcon} alt="">`;
            setIsRecording(false);
            setRecordingInProgress(false);
            setRecordingDuration(0); // Reset recording duration
            updateRecordTimeDisplay(); // Update record time display

            // Hide waveform during recording
            waveformRef.current.style.display = 'none';
        }

        // Stop playback if it's currently playing
        if (isPlaying) {
            waveSurferRef.current.stop();
            playPauseBtnRef.current.innerHTML = `<img src=${playIcon} alt="">`;
            setIsPlaying(false);
        }
    };

    // Toggle recording function
    const toggleRecording = () => {
        try {
            document
                .querySelector('.textarea-box .message')
                .classList.remove('orange', 'red');
            if (!isRecording) {
                configRecording();
            } else {
                setIsTranscribeDisabled(false);
                stopRecording();
            }
        } catch (error) {
            showWarning(error.message);
        }
    };

    const configRecording = async () => {
        setAudioBlob(null);
        setRecordingDuration(0); // Reset recording duration
        recordTimeDisplayRef.current.style.display = 'inline-block';
        positionSliderRef.current.style.display = 'inline-block';
        positionSliderRef.current.value = 0;
        startRecording();
    };

    // Toggle play or pause function
    const togglePlayPause = () => {
        try {
            if (!isPlaying) {
                setIsPlaying(true);
                waveSurferRef.current.play();
                playPauseBtnRef.current.innerHTML = `<img src=${pauseIcon} alt="">`;

                // Show play button when playback starts
                playPauseBtnRef.current.style.display = 'block';
                playPauseBtnRef.current.disabled = false;

                // Show waveform when playback starts
                waveformRef.current.style.display = 'block';
            } else {
                waveSurferRef.current.pause();
                playPauseBtnRef.current.innerHTML = `<img src=${playIcon} alt="">`;
                setIsPlaying(false);
            }
        } catch (error) {
            showWarning(error.message);
        }
    };

    const renderConfirmDialog = () => {
        return showSpeechModal
            ? createPortal(
                  <SpeechToTextModal message={AUDIO_TO_TEXT_IN_PROGRESS} />,
                  document.body
              )
            : null;
    };

    const renderNoSpeechToTextModal = () => {
        return isNoTextFromSpeech
            ? createPortal(
                  <SpeechToTextModal
                      message={SPEECH_NOT_TRANSCRIBED_TO_TEXT}
                      timeoutMethod={setNoSpeechFalse}
                  />,
                  document.body
              )
            : null;
    };

    const setNoSpeechFalse = () => {
        setIsNotTextFromSpeech(false);
    };

    const renderDeleteAudioDialog = () => {
        return showPopup
            ? createPortal(
                  <ConfirmationModal
                      onSuccess={DeleteAudio}
                      onCancel={onCancelDelete}
                      dialogData={dialogData}
                  />,
                  document.body
              )
            : null;
    };

    const onCancelDelete = () => {
        setShowPopup(false);
        hideDialog();
    };

    const initializeMediaRecorder = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            const mediaRecorder = new MediaRecorder(stream);
            streamRef.current = stream;
            mediaRecorderRef.current = mediaRecorder;
        } catch (error) {
            showWarning(MICROPHONE_NOT_ALLOWED);
        }
    };

    const handleDeleteAudio = () => {
        setShowPopup(true);
        showDialog<{ heading: string; body: string }>({
            heading: convertedTextFromSpeech
                ? DELETE_AUDIO_AND_TEXT
                : DELETE_AUDIO,
            body: convertedTextFromSpeech
                ? DELETE_AUDIO_AND_TEXT_BODY
                : DELETE_AUDIO_BODY,
        });
    };

    const DeleteAudio = () => {
        positionSliderRef.current.value = 0;
        if (convertedTextFromSpeech) {
            setMediaText('');
            setTextLength(0);
            setTextByAI(false);
        }
        setLocalAudioBlob(null);
        setAudioBlob(null);
        setShowPopup(false);
        setSelectedMediaType(null);
        setConvertedTextFromSpeech(false);
        setShowRecordAudioSection(false);
    };

    const handleCancelAudioSelection = () => {
        setShowRecordAudioSection(false);
    };

    useEffect(() => {
        if (audioBlob) {
            if (audioBlob && !textByAI) {
                setIsTranscribeDisabled(false);
            }
            setLocalAudioBlob(audioBlob);
        }
    }, [audioBlob]);

    useEffect(() => {
        const initialize = async () => {
            await initializeMediaRecorder();

            if (!audioBlob) {
                startRecording();
            }
        };

        initialize();

        const waveSurferInstance = WaveSurfer.create({
            container: waveformRef.current,
            height: 25,
            waveColor: '#C4C9D9',
            progressColor: '#440FCF',
            cursorWidth: 2,
            cursorColor: '#1D253F',
            barWidth: 2,
            barGap: 2,
            barRadius: 100,
            fillParent: true,
        });

        waveSurferRef.current = waveSurferInstance;

        if (audioBlob && waveSurferInstance) {
            waveSurferInstance.load(URL.createObjectURL(audioBlob));

            playPauseBtnRef.current.innerHTML = `<img src=${playIcon} alt="">`;
            waveformRef.current.style.display = 'block';
            playPauseBtnRef.current.style.display = 'block';
            playPauseBtnRef.current.disabled = false;
            playTimeDisplayRef.current.style.display = 'inline-block';
            recordTimeDisplayRef.current.style.display = 'none';
            positionSliderRef.current.style.display = 'none';
            positionSliderRef.current.value = 0;

            waveSurferInstance.on('ready', () => {
                setRecordingDuration(
                    Math.min(Math.floor(waveSurferInstance.getDuration()), 90)
                );
                updatePlayTimeDisplay(
                    0,
                    Math.min(Math.floor(waveSurferInstance.getDuration()), 90)
                );
                updatePositionSlider(0, true);
            });

            waveSurferInstance.on('finish', () => {
                setIsPlaying(false);
                playPauseBtnRef.current.innerHTML = `<img src=${playIcon} alt="">`;
            });

            waveSurferInstance.on('audioprocess', () => {
                const currentTime = waveSurferInstance.getCurrentTime();
                const recordedTime = waveSurferInstance.getDuration();
                updatePlayTimeDisplay(currentTime, recordedTime);
                updatePositionSlider(currentTime);
            });
        }

        return () => {
            waveSurferInstance.destroy();
            if (streamRef.current) {
                streamRef.current.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    return (
        <div className="audio-recording">
            <div className="audiorecorder row row-6 align-items-center justify-content-between">
                <div className="col-auto order-2 order-sm-1 fz-0">
                    <button
                        className="btn-empty-audio"
                        disabled={isRecording || isPlaying}
                        onClick={handleCancelAudioSelection}
                    >
                        <img src={crossIcon} alt="" />
                    </button>
                </div>
                {localAudioBlob && (
                    <div className="col-auto order-2 order-sm-1 fz-0">
                        <button
                            className="btn-delete"
                            disabled={isRecording || isPlaying}
                            onClick={handleDeleteAudio}
                        >
                            <svg
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.8335 15.5C2.37516 15.5 1.9828 15.3368 1.65641 15.0104C1.33002 14.684 1.16683 14.2917 1.16683 13.8333V3H0.333496V1.33333H4.50016V0.5H9.50016V1.33333H13.6668V3H12.8335V13.8333C12.8335 14.2917 12.6703 14.684 12.3439 15.0104C12.0175 15.3368 11.6252 15.5 11.1668 15.5H2.8335ZM4.50016 12.1667H6.16683V4.66667H4.50016V12.1667ZM7.8335 12.1667H9.50016V4.66667H7.8335V12.1667Z"
                                    fill="#AF91FF"
                                />
                            </svg>
                        </button>
                    </div>
                )}

                <div className="col-12 order-1 order-sm-2 col-sm mb-2 mb-sm-0">
                    <div className="row row-6 align-items-center">
                        {!isRecording && (
                            <div className="col-auto">
                                <button
                                    id="playPauseBtn"
                                    className="btn-play"
                                    ref={playPauseBtnRef}
                                    style={{ display: 'none' }}
                                    onClick={togglePlayPause}
                                >
                                    <img src={playIcon} alt="" />
                                </button>
                            </div>
                        )}

                        <div className="col fz-0">
                            <div
                                id="waveform"
                                ref={waveformRef}
                                style={{ display: 'none' }}
                            ></div>
                            <input
                                type="range"
                                className="range"
                                ref={positionSliderRef}
                                min="0"
                                max="100"
                                step="1"
                                defaultValue="0"
                            />
                        </div>
                        <div className="col-auto">
                            <div id="timeDisplay">
                                <span
                                    id="recordTimeDisplay"
                                    ref={recordTimeDisplayRef}
                                >
                                    <span>0:00</span>
                                    <span className="limit">/0:60</span>
                                </span>
                                <span
                                    id="playTimeDisplay"
                                    ref={playTimeDisplayRef}
                                    style={{ display: 'none' }}
                                >
                                    <span>0:00</span>/0:00
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto order-3 fz-0">
                    <button
                        id="recordBtn"
                        className="btn-record"
                        ref={recordBtnRef}
                        onClick={toggleRecording}
                        disabled={isPlaying}
                    >
                        <img src={recordIcon} alt="" />
                    </button>
                </div>
                <div className="col-auto order-4 fz-0">
                    <button
                        onClick={() => handleSpeechToText()}
                        className="btn-transform"
                        data-bs-toggle="modal"
                        data-bs-target="#modalaudio"
                        disabled={isTranscribeDisabled}
                    >
                        <svg
                            width="17"
                            height="14"
                            viewBox="0 0 17 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.5 13.6668V8.66683L7.16667 7.00016L0.5 5.3335V0.333496L16.3333 7.00016L0.5 13.6668Z"
                                fill="#6539D9"
                            />
                        </svg>
                    </button>
                </div>

                {showSpeechModal && renderConfirmDialog()}

                {showPopup && renderDeleteAudioDialog()}

                {isNoTextFromSpeech && renderNoSpeechToTextModal()}
            </div>
        </div>
    );
};

export default CustomAudio;
